/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import 'assets/css/font-awesome-pro/css/fontawesome';
@import 'assets/css/font-awesome-pro/css/regular';
@import 'assets/css/font-awesome-pro/css/solid';
@import 'assets/css/font-awesome-pro/css/brands';
@import 'assets/css/font-awesome-pro/css/v5-font-face';

html, body {
  background-color:#4e4e4e;
  font-family: "Poppins", "Open Sans", sans-serif;
  font-size:12px;
  padding: 0;
  margin: 0;
  height: 100%;
}

//------------------------------------global generic styles
body {
  overflow: hidden;
}

.page-content{
  position:relative;
  z-index:2;
  padding:10px;
}

a:focus, li:focus {
  outline: none;
}

.add-display-flex {
  display:flex;
}

.req {
  color: #b3000e;
  font-size:7px;
  vertical-align:super;
}

.full-width {
  width:100%;
}

.full-height {
  height:100%;
}

p-select {
  width:100%
}

.pointer {
  cursor:pointer;
}

.auto-width {
  width: auto !important ;
}

.remove-flex {
  display: block !important;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align:right;
}

.button-border {
  border:1px solid #fff;
}


.gold-tier {
  color: #FFD700;
}

.silver-tier {
  color: #c0c0c0;
}

.no-margin {
  margin:0px;
}

.no-padding {
  padding:0px;
}
//----------------------------------end universal styles

//----------------------------------login
.login-card {
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  z-index: 5;
  position: relative;
  max-width: 475px;
  background-color: transparent;
  box-shadow: none;
  padding-top:21%;

  .p-card .p-card-body {
      padding:20px;
    }

  .p-inputtext {
    border-color: #232a31;
    background: #232a31;
    color: #fff;
    font-size: 22px;
    padding:7px;
    margin-top:5px;
  }


}

.login-page {
  background-color:#111418 ;
  height:100%;
  width:100%;

  .image-container {
    text-align:center;
    margin-top:55%;
    img {

    }
  }

  .p-checkbox {
    width: 20px !important;
    height: 20px !important;
  }

  .text-container {
    color:#ffffff;
    font-size:36px;
    padding:10px 80px;
    text-align:left;
    margin-top:10px;
    text-align:center;
  }


  h2 {
    color:#ffffff;
    font-size:27px;
    text-align:center;
    font-weight:normal;
  }

  .left {
    border-right: 1px solid rgba(256, 256, 256, .3);
    height: 100%;
    background-image: url("./assets/images/login-bg.png");
    background-position: center center;
  }

  label {
    color:#ffffff;

  }

  .p-card {
    background-color:#111418 ;
  }
  .card {
    border-radius: 0px;
    background: transparent;
    padding: 0px;
    box-sizing: unset;
    box-shadow: none;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    margin-bottom: 0px;
  }

}

.login-page-background {
  background-image:url("assets/images/wallpaper4.jpg");
  opacity:.2;
  background-size:cover;
  position:absolute;
  height:100%;
  width:100%;
  z-index:0;
  top:0px;
}

//---------------------------------------Tenant Specific Styles
.content-background-image {
  position:absolute;

  width:100%;
  opacity:.05;
  z-index: 1;
}

.router-container {
  position:relative;
  z-index: 15;
}
//---------------------------------------End Tenant

//---------------------------------------start page header
.page-header {
  background-color: #384F7A;
  margin-bottom:5px;
  display:inline-flex;
  position: static;

  .page-header-title {
    color:#fff;
    font-size: 25px;
    margin: 10px 0px 10px 10px;

    i {
      font-size: 20px;
    }

  }


  .back-button {
    height:100%;
    background-color: #fff;
    color: #3C4962;
    border-radius: 0px;
  }

  .add-button {
    position: absolute;
    right: 8px;
    background-color: #eee;
    color: #3C4962;
    border-radius: 50%;
    border: 0px;
    top: 8px;
    padding: 14px;
    width: auto;
  }
}

.fixed-header {
  position: fixed;
  width: 100%;
  z-index: 10000;
}


.page-container {
  overflow-x: hidden;
  overflow-y: scroll;
  /* position: relative; */
  height: 100%;
  padding-bottom:100px;
}

.page-margin {
  margin-left:0px;
  margin-right:0px;
  margin-top:0px;
}

.layout-topbar-logo {
  float:left;
  margin-top:10px;
}

//---------------------------------------End Page header

//---------------------------------------Button Styles
.p-button-auto-width {
  .p-button {
    width: auto;
  }
}


//--------------------------------------End Button Styles


//--------------------------------------table styles
table {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
}

.list-paginator {
  padding:0px 12px;
}

.custom-table {
  table {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    overflow: visible;
  }

}

.root-menu {
  .p-datatable .p-datatable-tbody > tr {
    background: #2e3744;
    color: #fff8f2;

    .sub-menu-table {
      .p-datatable .p-datatable-tbody > tr {
        background: #455368;
        color: #fff8f2;

        .sub-menu2-table {
          .p-datatable .p-datatable-tbody > tr {
            background: #eee;
            color: #1c202c;
          }
        }
      }
    }
  }


}
//--------------------------------------end table styles

//--------------------------------------filter styles
.filter-header {
  padding:10px;
  margin-top:50px;
  label {
    color:#fff;
    font-weight: bold;
  }
}

//--------------------------------------end filter styles

//---------------------------------------card styles
.tenant-header {
  background-color:#000;
  height:60px;
  margin-bottom:20px;
  padding-top:10px;
  text-align:center;
  position:relative;
  z-index:10;

  .tenant-logo {
    img {
      height: 40px;
    }

  }
}

.p-card {
  position:relative;
}

.inactive-flag {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/inactive-flag.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
}

.suspended-flag {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/suspended-flag.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
}

.gray-flag {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/gray-flag.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
}

.green-flag {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('./assets/images/green-flag.png');
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
}

.list-card .list-header {
  font-size: 20px;
}

.child-card {
  background-color:#eee;
}
//---------------------------------------end card styles

//--------------------------------------- tier styles

.bronze-gradient {
  .p-card {
    color:#333;
    background: linear-gradient(to bottom, #9b8e71 2%,#d9cfb5 50%,#9b8e71 100%);
    margin-bottom:10px;
    h3 {
      font-size:16px;
      margin:4px 0px;
      padding:0px;
    }

    .p-card-content {
      padding:0px;
    }


  }
}

.active-card {
  border: 4px solid #fff;
  min-height:150px;
}

.silver-gradient {
  .p-card {
    color:#333;
    background: linear-gradient(to bottom, #969796 1%,#cbccca 50%,#969796 100%);
    margin-bottom:10px;
    h3 {
      font-size:16px;
      margin:4px 0px;
      padding:0px;
    }

    .p-card-content {
      padding:0px;
    }

  }
}

.gold-gradient {
  .p-card {
    color:#333;
    background: linear-gradient(to bottom, #a5853a 1%,#f1e7ac 50%,#a5853a 100%);
    margin-bottom:10px;
    h3 {
      font-size:16px;
      margin:4px 0px;
      padding:0px;
    }

    .p-card-content {
      padding:0px;
    }

  }
}

.sub-package-card {
  .p-card {
    background:#fff;
    min-height:225px;
  }
}

.sub-package-header {
  .p-card {
    background: #fff;
  }
}


//---------------------------------------- end tier styles

//---------------------------------------form styles
.form-container {
  padding:20px;
}

.tab-form-buttons {
  margin-top:10px;
  text-align:right;

  .p-button {
    width:auto;
    margin-right:3px;
  }
}


.list-filter-buttons {
  padding-top:16px;
  button {
    display: inline-flex;
  }

  .p-button {
    font-weight: bold;
    width: auto;
    margin-left: 5px;
    background-color: #3C4962;
    color: #fff;
    border:1px solid #fff;
  }
}

.env-container {
  display:inline-block;
}

.p-inputtext {
  width:100%;
}

//---------------------------------------end form styles




.tenant-selector {
  margin: 20px 0px 0px 0px;
  padding:0px 20px;

  label {
    color:#fff;
    font-weight: bold;
  }
}

.layout-menuitem-text {
  text-transform: capitalize;
  font-family: "Open Sans", sans-serif;
  font-size:15px;
  font-weight:500 !important;
  -webkit-font-smoothing: antialiased;
  color: #fff;
}

.layout-menuitem-icon {
  font-size:16px !important;
  width: 15px;
  text-align: left;
  text-rendering: auto;
  color: #fff;
}

.p-panelmenu-item-content {
  margin:4px 0px;
  cursor: pointer;
  padding:5px;
}

.layout-menu-tooltip-text {
  font-size:15px;
  color:#fff;
}

.p-panelmenu-item-content:hover {
  color:#666 !important;
}


.custom-icon {
  font-size:16px;
  color: #fff;
  text-rendering: auto;
  .layout-menuitem-text {
    text-transform: capitalize;
    font-family: "Open Sans", sans-serif;

  }

  .layout-menuitem-icon {
    display:none;
  }
}

.layout-menuitem-icon:hover {
  color:#666;
}

.layout-menuitem-text:hover {
  color:#666;
}

.p-panelmenu-content-container, .p-panelmenu-panel, .p-panelmenu-content{
  background: transparent !important;
  border: none !important;
}

.p-panelmenu .p-panelmenu-content {
  background:transparent;
}

.p-panelmenu-submenu-icon {
  color:#fff !important;
}



.fa-grid-horizontal::before, .fa-address-book::before {
    font-size:16px;
  width:15px;
  }




footer a {
  color:#fff;
  font-weight:bold;
}


//---------------------------------------sidebar---------------------------//
.ng-sidebar {
  background-color: #F8F9FD;
  border-right: 1px solid rgba(0, 0, 0, .12);
  box-shadow: 0 0 4px rgba(0, 0, 0, .14), 2px 4px 8px rgba(0, 0, 0, .28);
  width: 239px;
}

.ng-sidebar-content {
  padding: 0 0 0 0;
  min-height: 100%;
  min-width: 100%;
  height:100%;
  position: absolute;
  overflow: hidden;
}

.ng-sidebar__content {
  //TODO test before removing
  //position:unset !important;
  //height:calc(100% - 94px) !important;
  // overflow:visible !important;

}

.ng-sidebar-container {
  //TODO test before removing
  //height:calc(100% - 86px) !important;
  //height:calc(100% - 120px) !important;
  //overflow:visible !important;
  overflow-y: hidden;
  z-index: 900;
  width: 100%;
  height: 100%;

}

.custom-sidebar {
  background-color: #354056;
  border-right: 1px solid rgba(0, 0, 0, .12);
  box-shadow: 0 0 4px rgba(0, 0, 0, .14), 2px 4px 8px rgba(0, 0, 0, .28);
  width: 239px;
  height: 100%;
  position: absolute;
  z-index: 2000;
  transition: 1s cubic-bezier(.36,-0.01,0,.77);
  padding:5px;
}

.custom-sidebar .closed {
  margin-left: -200px;
}

.layout-menu {
  margin:0px;
  padding:0px;
}

//---------------------------------------end sidebar---------------------------//

//---------------------------------------- Alerts sidebar --------------------//
.alertSidebar {
  .ng-sidebar {
    background-color: #dddddd;
    max-width: 350px;
  }
}

//--------------------------------------- End alerts sidebar ------------------//


//---------------------------------------- List Card styles -----------------------------//
.parent-p-card .p-card .list-card {
  background-color:#F6F4F4;

}

.state-cards {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width:24%;
  margin:15px .5%;
  flex: 1 0 auto;


  .p-card {
    height: 100%;
    background-color:#fff !important;
  }
}


.list-filter-container{
  min-height:500px;
}

.address-card {
  .p-card {
    background-color: #f5f5f5 !important;
  }

}

.business-template-card {
  .p-card {
    background-color: #F6F4F4 !important;
    padding:15px 10px;
    margin: 5px;
  }
}

.alpha-buttons {
  float:left;

  .p-button {
    width: auto;
  }
}

.list-card {
  //margin:10px 0;
  background-color:#F6F6F6;
  box-shadow: none;
  border:1px solid #979797;
  border-radius:8px;

  .p-card-content {
    margin: 15px 0px 0px;
    padding: 0;
  }

  .list-header {
    font-size:18px;
    font-weight:bold;
  }


  .left-column {
    cursor:pointer;
  }

  .right-column {

  }
}
.parent-p-card .p-card {
  .address-card {
    padding:5px;

    .list-header {
      font-size:16px;
    }

    .list-subheader {
      margin-top:5px;
    }
  }
}



.address-container {
  height: 340px;
  overflow:visible;
}

.list-action-buttons {
  float:none;
  display:flex;
  align-items: center;
  justify-content: right;
}

.center-profile {
  display: flex;
  justify-content: center;
}

.tab-container-profile {
  display: flex;
  justify-content: center;
  padding-top:5px;
}

.list-container {
  margin-top: 12px;
  padding:0px 15px;

  .avatar {
    float:left;
    margin-right:5px;
  }

  .profile-container {
    display:flex;
    justify-content: center;
  }

  .action-buttons {
    float:right;
  }

  .icon {
    margin:3px;
    font-size:15px;
    background-color:#607D8B;
    width:25px;
    height:25px;
    text-align: center;
    border-radius: 3px;
    padding:2px;
    cursor: pointer;

    i {
      color:#fff;
      font-family: "Font Awesome 6 Pro" !important;
    }
    &:hover {
      background-color:#1E88E3;
    }
  }

  .list-switch {
    float:left;
    padding-top: 2px
  }
}

.list-spinner {
  position: relative;
  top: 10px;
  left: 50%;
  font-size: 30px;
  color: #607d8b;
}

.results-total {
  padding-left:25px;
}


.list-container-header {
  .action-buttons {
    text-align:right;
    padding-right:25px;
  }

  .add-entry {
    //margin-top:20px;
  }

  .list-paginator-mobile {
    display: none;
  }

  button {
    margin:8px 0 0 3px;
  }

  p-inputNumber {
    button {
      margin: 0;
    }
  }

  .p-button-info {
    border: 0;
    //background-color: #1788bf;
  }
}

.p-tabview-panel {
  button {
    margin: 0;
  }

  .action-buttons {
    button {
      margin-left:3px;
    }
  }
}

.list-container-filter-header {
  .action-buttons {
    text-align:right;
  }

  label {
    font-size:12px;
  }

  button {
    // margin:0px 0 5px 3px;
  }

  .p-button-info {
    border: 0;
    // background-color: #1788bf;
  }
}

.list-container-header {
  form {
    button {
      margin: 0;
    }
  }
}

.org-list {
  .list-subheader {
    font-size: 13px;
    font-weight: 400;
  }
}

//--------------------------------- Avatar list ----------------------//

.avatar-with-image {
  overflow: hidden;
  border-radius: 50%;
  display:flex;
  max-height: 75px;
  max-width: 75px;
  align-content: center;
  img {
    width: 100%;
  }
}

.avatar-without-image {
  overflow: hidden;
  border-radius: 50%;
  display:flex;
  max-height: 75px;
  max-width: 75px;
  align-content: center;
  img {
    width: 100%;
  }
}

.org-avatar-with-image {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 60px;
  width: 60px;
  align-content: center;
  //border: 2px solid #fff;
  box-shadow: 0 0 0 1px #000000;
  vertical-align: middle;

  img {
  }
}

.sub-page-container {
  padding:15px;
}





//-----------------------------------------footer styles----------------------------//

#footer {
  background: #ccc;
  /*font-size: 0.9rem;*/
  z-index: 900;
  line-height: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 25px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  a {
    color: #444;
    text-decoration: none;
  }
}

footer {
  z-index: 50000;
}
